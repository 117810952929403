import React from 'react';
import './css/Merch.css';

function Merch() {
	return (
		<div className="page">
			<h1 className="title">Merch</h1>
			<h2 id="tempMessage">Coming Soon!</h2>
		</div>
	);
}
  
export default Merch;
  